import { debounce, downloadExcelFile, downloadPdfFile, formatDateTime, formatNumber, isMobile } from "@/libs/helpers";
import { httpClient } from "@/libs/http";
import { IMAGE_OPTIONS, YES_NO_OPTIONS, RECEIPT_DETAIL_STATUS_OPTIONS } from "@/libs/const";

export default {
    data: () => ({
        page: 1,
        totalPage: 1,
        items: [],
        updatingItem: {},
        filters: {
            /* sort_by: 'employee_name',
            sort_type: 'asc' */
        },
        sort: {},
        imageOptions: [...IMAGE_OPTIONS],
        yesNoOptions: [...YES_NO_OPTIONS],
        statusOptions: [...RECEIPT_DETAIL_STATUS_OPTIONS],
        checkDialog: false,
        isLoading: false,
        viewOption: 'view0',
        sum: {},
        dialogReceiptFast: false,
        dialogReceiptHasStamp: false,
        qrScanDialog: false,
        qrScanType: null,
    }),
    watch: {
        page() {
            this.getList(false)
        },
        filters: {
            handler() {
                this.page = 1
                this.getList(true)
            },
            deep: true
        }
    },
    mounted() {
        this.getList(false)
        this.getActiveStamp()
    },
    methods: {
        formatDateTime,
        downloadPdfFile,
        downloadExcelFile,
        formatNumber,
        isMobile,
        setViewOption(mode) {
            this.viewOption = mode
        },
        showQRCodeScan(type) {
            this.qrScanDialog = true
            this.qrScanType = type
        },
        hideQRCodeScan() {
            this.qrScanDialog = false
            this.qrScanType = null
        },
        onQRScanned(filter) {
            this.filters = { ...this.filters, [filter.name]: filter.value }
            this.getList()
        },
        getStorageLocation(locations) {
            if (!locations) {
                return ''
            }
            const locationSplit = locations.split(';')
            return locationSplit.join('<br>')
        },
        getList: debounce(function (showItem = false) {
            httpClient.post('/goods-receipt/v1/list-waiting', { ...this.filters, page: this.page }).then(({ data }) => {
                this.totalPage = data.totalPage
                this.items = [...data.rows]
                this.sum = { ...data.sum }
                if (this.items && this.items.length === 1 && showItem) {
                    const item = this.items[0]
                    if (!item.status && !this.isDisabledBtnCheck(item)) {
                        this.showCheckDialog(item)
                    }
                }
            })
        }, 500),
        onFilterChange(filter) {
            this.filters = { ...this.filters, [filter.name]: filter.value }
        },
        onSortChange(sort) {
            // this.sort = {...this.sort, [sort.name]: sort.value}
            // this.sort = {...sort}
            this.filters = { ...this.filters, ...sort }
        },
        async exportExcel() {
            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.waiting_file_download'))
                return false
            }
            this.isLoading = true
            const filename = 'nhap-kho-kiem-dem.xlsx'

            try {
                await this.downloadExcelFile('/goods-receipt/v1/list-waiting-export', { ...this.filters, viewMode: this.viewOption }, filename)
                this.isLoading = false
            } catch (e) {
                this.isLoading = false
            }
        },
        showCheckDialog(item) {
            if (this.isDisabledBtnCheck(item)) {
                this.$vToastify.error(this.$t('labels.no_permission_process'))
                return false
            }
            this.checkDialog = true
            this.updatingItem = { ...item }
        },
        hideCheckDialog() {
            this.checkDialog = false
            this.updatingItem = {}
        },
        isDisabledBtnStatus(item) {
            if (!item || !item.id_employee || !window.me || window.me.employee.id !== item.id_employee) {
                return true
            }
            return false
        },
        isDisabledBtnCheck(item) {
            /* if (!item || (item.id_employee && window.me.employee.id !== item.id_employee) || item.has_stamp) {
                return true
            } */
            const workConfigs = item.work_configs && JSON.parse(item.work_configs) || []
            const warehouseConfig = [...workConfigs].find(cf => cf.key == 'receipt_quantity')
            const maxQuantity = warehouseConfig && warehouseConfig.quantity || 200
            if (!item || (item.id_employee && window.me.employee.id !== item.id_employee && item.request_quantity < maxQuantity) || item.has_stamp || item.is_transfer) {
                return true
            }
            return false
        },
        async updateStatus(item, type) {
            if (this.isDisabledBtnStatus(item)) {
                this.$vToastify.error(this.$t('labels.no_permission_process'))
                return false
            }
            if (!confirm(this.$t('messages.are_you_sure'))) {
                return false
            }
            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.loading'));
                return false
            }
            this.isLoading = true

            try {
                await httpClient.post('/goods-receipt/v1/update-status', {
                    id: item.id,
                    type
                })
                this.isLoading = false
                this.getList(false)
                document.getElementById('success_sound_player').play()
            } catch (e) {
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg);
                this.isLoading = false
                document.getElementById('error_sound_player').play()
            }
        },
        async getActiveStamp() {
            if (
                window.me &&
                window.me.working &&
                window.me.working.receipt &&
                window.me.working.receipt_item
            ) {
                const working = JSON.parse(window.me.working.receipt_item);
                if (working.id_goods && working.id_goods_receipt_detail) {
                    this.filters = { ...this.filters, id_goods: working.id_goods, id_goods_receipt_detail: working.id_goods_receipt_detail }
                }
            }
            /* try {
                const { data } = await httpClient.post('/goods-receipt/v1/active-stamp-by-employee')
                if (data && data.insert_tracking) {
                    // this.$vToastify.warning(this.$t('messages.pending_stamp', {stamp_number: data.count_id}));
                    this.filters = { ...this.filters, id_goods: data.id_goods, id_goods_receipt_detail: data.id_goods_receipt_detail }
                }
            } catch (e) {
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg);
            } */
        },
        showReceiptFast() {
            this.dialogReceiptFast = true
        },
        hideReceiptFast() {
            this.dialogReceiptFast = false
        },
        showReceiptHasStamp() {
            this.dialogReceiptHasStamp = true
        },
        hideReceiptHasStamp() {
            this.dialogReceiptHasStamp = false
        },
    },
}
