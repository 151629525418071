<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 145px)"
      class="table-padding-2"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <!-- <th role="columnheader" style="min-width: 100px">
              <SelectWarehouse
                :label="$t('labels.warehouse')"
                :placeholder="$t('labels.warehouse')"
                name="id_warehouse"
                sort-name="warehouse_code"
               
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th> -->
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :label="$t('labels.group_tracking')"
                :placeholder="$t('labels.group_tracking')"
                name="group_tracking"
                sort-name="group_tracking"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :label="$t('labels.session')"
                :placeholder="$t('labels.session')"
                name="tracking_id"
                sort-name="tracking_id"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <template v-if="['view1'].includes(viewOption)">
              <th role="columnheader" style="min-width: 100px">
                <DateRangeFilter
                  :label="$t('labels.created_at')"
                  :placeholder="$t('labels.created_at')"
                  name="create_time"
                  sort-name="create_time"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
            </template>
            <th role="columnheader" style="min-width: 100px">
              <SelectFilter
                :options="imageOptions"
                :label="$t('labels.image')"
                :placeholder="$t('labels.image')"
                name="image"
                sort-name="url_images"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :label="$t('labels.name')"
                :placeholder="$t('labels.name')"
                name="goods_name"
                sort-name="goods_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :label="$t('labels.goods_description')"
                :placeholder="$t('labels.goods_description')"
                name="goods_description"
                sort-name="goods_description"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <SelectFilter
                :options="statusOptions"
                :label="$t('labels.status')"
                :placeholder="$t('labels.status')"
                name="status"
                sort-name="status"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                class="input-highlight"
                :label="$t('labels.barcode')"
                :placeholder="$t('labels.barcode')"
                name="customer_goods_barcode"
                sort-name="customer_goods_barcode"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                class="input-highlight"
                :label="$t('labels.sku')"
                :placeholder="$t('labels.sku')"
                name="sku"
                sort-name="sku"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :label="$t('labels.size')"
                :placeholder="$t('labels.size')"
                name="goods_size"
                sort-name="goods_size"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 80px">
              <InputFilterFromTo
                :label="$t('labels.request_quantity')"
                :placeholder="$t('labels.request_quantity')"
                name="request_quantity"
                sort-name="request_quantity"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 80px">
              <InputFilterFromTo
                :label="$t('labels.receipt_quantity')"
                :placeholder="$t('labels.receipt_quantity')"
                name="receipted_quantity"
                sort-name="receipted_quantity"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 80px">
              <InputFilterFromTo
                :label="$t('labels.sub_quantity')"
                :placeholder="$t('labels.sub_quantity')"
                name="sub_quantity"
                sort-name="sub_quantity"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :label="$t('labels.note')"
                :placeholder="$t('labels.note')"
                name="note"
                sort-name="note"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <!--
          <th role="columnheader" style="min-width: 100px">
            <InputFilter :label="$t('labels.position')" :placeholder="$t('labels.position')" name="storage_location" sort-name="storage_location" :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>
          -->
            <template v-if="['view1'].includes(viewOption)">
              <th role="columnheader" style="min-width: 100px">
                <DateRangeFilter
                  :label="$t('labels.time')"
                  :placeholder="$t('labels.time')"
                  name="last_handle_time"
                  sort-name="last_handle_time"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th>
                <SelectFilter
                  :options="yesNoOptions"
                  :label="$t('labels.transfer')"
                  :placeholder="$t('labels.transfer')"
                  name="is_transfer"
                  sort-name="is_transfer"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
            </template>
            <th role="columnheader" style="min-width: 100px">
              <SelectCustomer
                :label="$t('labels.customer')"
                :placeholder="$t('labels.customer')"
                name="id_customer"
                sort-name="company_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <!--
          <th role="columnheader" style="min-width: 100px">
            <InputFilter :label="$t('labels.sub_barcode')" :placeholder="$t('labels.sub_barcode')" name="sub_barcode" sort-name="sub_barcode" :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>
          -->
            <th role="columnheader" style="min-width: 100px">
              <SelectEmployee
                :label="$t('labels.employee_process')"
                :placeholder="$t('labels.employee_process')"
                name="id_employee"
                sort-name="employee_name"
                :sorting="filters.sort_by"
                default-sort="asc"
                url="/employee/v1/get-by-my-warehouse"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id" class="text-center">
            <!-- <td> -->
            <!-- <div style="font-size: 18px">{{ item.warehouse_code }}</div> -->
            <!-- <div style="font-size: 12px; font-weight: normal">
              <span class="info--text">{{ item.warehouse_free_cell }}/{{ item.warehouse_total_cell }}</span>
            </div> -->
            <!-- </td> -->
            <td>{{ item.group_tracking }}</td>
            <td>
              <div style="font-size: 18px">{{ item.tracking_id }}</div>
              <!-- <div style="font-size: 12px; font-weight: normal">
              <span class="info--text">{{ item.tracking_total_handled }}/{{ item.tracking_total_request }}</span>
              <span class="error--text"> || </span>
              <span class="info--text">{{ item.tracking_count_handled }}/{{ item.tracking_count_request }}</span>
            </div> -->
            </td>
            <template v-if="['view1'].includes(viewOption)">
              <td>{{ formatDateTime(item.create_time) }}</td>
            </template>
            <td>
              <ImageViewer v-if="item.url_images" :url="item.url_images" />
            </td>
            <td>{{ item.goods_name }}</td>
            <td>{{ item.goods_description }}</td>
            <td style="min-width: 100px">
              <v-btn
                color="primary"
                x-small
                class="mr-1"
                v-if="!item.status"
                @click="showCheckDialog(item)"
                :disabled="isDisabledBtnCheck(item)"
                >{{ $t("labels.check") }}</v-btn
              >
              <v-btn
                color="warning"
                x-small
                v-if="!item.status"
                @click="updateStatus(item, 'confirm')"
                :disabled="isDisabledBtnStatus(item)"
                >{{ $t("labels.done_1") }}</v-btn
              >
              <v-btn
                color="secondary"
                x-small
                v-if="item.status"
                @click="updateStatus(item, 'reopen')"
                :disabled="isDisabledBtnStatus(item)"
                style="width: 86px"
                >{{ $t("labels.reopen") }}</v-btn
              >
            </td>
            <td>
              <span style="font-size: 16px; font-weight: 600">{{
                item.customer_goods_barcode
              }}</span>
            </td>
            <td>
              <span style="font-size: 16px; font-weight: 600">{{
                item.sku
              }}</span>
            </td>
            <td>{{ item.goods_size }}</td>
            <td>
              <span class="text-h5 success--text">{{
                item.request_quantity
              }}</span>
            </td>
            <td>
              <span class="text-h5 info--text">{{
                item.receipted_quantity || 0
              }}</span>
            </td>
            <td>
              <span class="text-h5 error--text">{{ item.sub_quantity }}</span>
            </td>
            <td>{{ item.note }}</td>
            <!--
          <td><div v-html="getStorageLocation(item.storage_location)"></div></td>
          -->
            <template v-if="['view1'].includes(viewOption)">
              <td>
                {{
                  item.last_handle_time
                    ? formatDateTime(item.last_handle_time)
                    : ""
                }}
              </td>
              <td>{{ item.is_transfer ? "Yes" : "No" }}</td>
            </template>
            <td>{{ item.company_name }}</td>
            <!--
          <td>{{ item.sub_barcode }}</td>
          -->
            <td>{{ item.employee_name }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row class="pt-3">
      <v-col cols="12" sm="4">
        <div class="d-flex">
          <div>
            <div class="table-footer-count mr-2">
              {{ $t("labels.sku") }}:
              <b
                >{{ formatNumber(sum.sumChecked) }}/{{
                  formatNumber(sum.sumGoods)
                }}</b
              >
            </div>
            <div class="d-flex table-footer-count mt-1">
              <div class="mr-2">
                {{ $t("labels.request_quantity") }}:
                <b>{{ formatNumber(sum.sumRequestQuantity) }}</b>
              </div>
              <div class="mr-2">
                {{ $t("labels.receipt_quantity") }}:
                <b>{{ formatNumber(sum.sumReceiptedQuantity) }}</b>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="4">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="3"
        ></v-pagination>
      </v-col>
      <v-col cols="12" sm="4">
        <div class="d-flex align-center justify-center">
          <v-btn
            color="success"
            small
            @click="exportExcel"
            v-if="checkPermission(['goods_receipt_waiting_excel'])"
            style="height: 40px"
            class="mr-2"
            ><v-icon>mdi-download</v-icon> {{ $t("labels.excel") }}</v-btn
          >
          <v-btn
            color="secondary"
            small
            class="mr-2"
            style="height: 40px"
            @click="setViewOption('view0')"
            v-if="['view1'].includes(viewOption)"
            ><v-icon>mdi-arrow-collapse</v-icon>
            {{ $t("views.compact") }}</v-btn
          >
          <v-btn
            color="primary"
            small
            class="mr-2"
            style="height: 40px"
            @click="setViewOption('view1')"
            v-if="['view0'].includes(viewOption)"
            ><v-icon>mdi-arrow-expand</v-icon> {{ $t("views.full") }}</v-btn
          >

          <v-text-field
            class="c-input-xs"
            dense
            outlined
            hide-details
            v-model.number="page"
            :label="$t('labels.page')"
            :placeholder="$t('labels.page')"
          ></v-text-field>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="checkDialog" persistent max-width="1366px">
      <CheckSpecialGoods
        v-if="checkDialog && updatingItem.goods_special"
        :item="updatingItem"
        @cancel="hideCheckDialog"
        @refreshData="getList"
      />
      <CheckNormalGoods
        v-if="checkDialog && !updatingItem.goods_special"
        :item="updatingItem"
        @cancel="hideCheckDialog"
        @refreshData="getList"
      />
    </v-dialog>
  </div>
</template>

<script>
import waitingMixins from "@/components/goods_receipt/mixins/waitingMixins";

export default {
  components: {
    // SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
    SelectCustomer: () => import("@/components/table/SelectCustomer"),
    SelectEmployee: () => import("@/components/table/SelectEmployee"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    ImageViewer: () => import("@/components/goods/ImageViewer"),
    CheckNormalGoods: () =>
      import("@/components/goods_receipt/CheckNormalGoods"),
    CheckSpecialGoods: () =>
      import("@/components/goods_receipt/CheckSpecialGoods"),
  },
  name: "WaitingDesktop",
  mixins: [waitingMixins],
};
</script>

<style scoped></style>
